import { Size } from 'types/paint-by-numbers';

export const BASE_PRICE = 426;
export const CELEBRATION_DISCOUNT = 15;
export const BASE_DISCOUNT = 15;
export const PAPER_COUNT_FOR_ORDER = 2;

export const STANDARD_SETS = {
	s12: 'Стандартный набор 12 цветов',
	s18: 'Стандартный набор 18 цветов',
	s24: 'Стандартный набор 24 цвета',
	s28: 'Стандартный набор 28 цветов',
	s36: 'Стандартный набор 36 цветов',
	s48: 'Стандартный набор 48 цветов',
	s50: 'Стандартный набор 50 цветов',
	s72: 'Стандартный набор 72 цвета',
} as const;

export const ASPECT_RATIO = {
	[Size.x30]: 1,
	[Size.x5040]: 5 / 4,
	[Size.x50]: 1,
	[Size.A5]: 1.414,
	[Size.A4]: 1.414,
	[Size.A3]: 1.414,
};

export const STANDARD_COLOR_SETS: Record<keyof typeof STANDARD_SETS, string[]> = {
	s12: [
		'#1e1816',
		'#192244', 
		'#32287b', 
		'#097233', 
		'#88432a', 
		'#e86574', 
		'#f0c18e', 
		'#60aa3b', 
		'#f08113', 
		'#c21b1a', 
		'#066fad',
		'#f8e018',
	],
	s18: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080',  // Navy
	],
	s24: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC',  // Beige
	],
	s28: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC', // Beige
		'#8A2BE2', // BlueViolet
		'#228B22', // ForestGreen
		'#FF4500', // OrangeRed
		'#B8860B',  // DarkGoldenRod
	],
	s36: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC', // Beige
		'#8A2BE2', // BlueViolet
		'#228B22', // ForestGreen
		'#FF4500', // OrangeRed
		'#B8860B', // DarkGoldenRod
		'#4682B4', // SteelBlue
		'#32CD32', // LimeGreen
		'#9932CC', // DarkOrchid
		'#800000', // Maroon
		'#8B4513', // SaddleBrown
		'#2E8B57', // SeaGreen
		'#20B2AA', // LightSeaGreen
		'#D2691E',  // Chocolate
	],
	s48: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC', // Beige
		'#8A2BE2', // BlueViolet
		'#228B22', // ForestGreen
		'#FF4500', // OrangeRed
		'#B8860B', // DarkGoldenRod
		'#4682B4', // SteelBlue
		'#32CD32', // LimeGreen
		'#9932CC', // DarkOrchid
		'#800000', // Maroon
		'#8B4513', // SaddleBrown
		'#2E8B57', // SeaGreen
		'#20B2AA', // LightSeaGreen
		'#D2691E', // Chocolate
		'#800080', // Purple
		'#DA70D6', // Orchid
		'#DB7093', // PaleVioletRed
		'#708090', // SlateGray
		'#006400', // DarkGreen
		'#DC143C', // Crimson
		'#7B68EE', // MediumSlateBlue
		'#BA55D3', // MediumOrchid
		'#8B008B', // DarkMagenta
		'#FF1493', // DeepPink
		'#FF6347',  // Tomato
	],
	s50: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC', // Beige
		'#8A2BE2', // BlueViolet
		'#228B22', // ForestGreen
		'#FF4500', // OrangeRed
		'#B8860B', // DarkGoldenRod
		'#4682B4', // SteelBlue
		'#32CD32', // LimeGreen
		'#9932CC', // DarkOrchid
		'#800000', // Maroon
		'#8B4513', // SaddleBrown
		'#2E8B57', // SeaGreen
		'#20B2AA', // LightSeaGreen
		'#D2691E', // Chocolate
		'#800080', // Purple
		'#DA70D6', // Orchid
		'#DB7093', // PaleVioletRed
		'#708090', // SlateGray
		'#006400', // DarkGreen
		'#DC143C', // Crimson
		'#7B68EE', // MediumSlateBlue
		'#BA55D3', // MediumOrchid
		'#8B008B', // DarkMagenta
		'#FF1493', // DeepPink
		'#FF6347', // Tomato
		'#556B2F', // DarkOliveGreen
		'#8FBC8F', // DarkSeaGreen
	],
	s72: [
		'#FF0000', // Red
		'#0000FF', // Blue
		'#00FF00', // Green
		'#FFFF00', // Yellow
		'#FFA500', // Orange
		'#800080', // Purple
		'#FFC0CB', // Pink
		'#A52A2A', // Brown
		'#000000', // Black
		'#FFFFFF', // White
		'#00FFFF', // Cyan
		'#FF00FF', // Magenta
		'#00FF00', // Lime
		'#008080', // Teal
		'#4B0082', // Indigo
		'#800000', // Maroon
		'#808000', // Olive
		'#000080', // Navy
		'#7FFFD4', // Aquamarine
		'#40E0D0', // Turquoise
		'#FFD700', // Gold
		'#C0C0C0', // Silver
		'#808080', // Gray
		'#F5F5DC', // Beige
		'#8A2BE2', // BlueViolet
		'#228B22', // ForestGreen
		'#FF4500', // OrangeRed
		'#B8860B', // DarkGoldenRod
		'#4682B4', // SteelBlue
		'#32CD32', // LimeGreen
		'#9932CC', // DarkOrchid
		'#800000', // Maroon
		'#8B4513', // SaddleBrown
		'#2E8B57', // SeaGreen
		'#20B2AA', // LightSeaGreen
		'#D2691E', // Chocolate
		'#800080', // Purple
		'#DA70D6', // Orchid
		'#DB7093', // PaleVioletRed
		'#708090', // SlateGray
		'#006400', // DarkGreen
		'#DC143C', // Crimson
		'#7B68EE', // MediumSlateBlue
		'#BA55D3', // MediumOrchid
		'#8B008B', // DarkMagenta
		'#FF1493', // DeepPink
		'#FF6347', // Tomato
		'#556B2F', // DarkOliveGreen
		'#8FBC8F', // DarkSeaGreen
		'#8B0000', // DarkRed
		'#483D8B', // DarkSlateBlue
		'#2F4F4F', // DarkSlateGray
		'#00CED1', // DarkTurquoise
		'#BDB76B', // DarkKhaki
		'#FF8C00', // DarkOrange
		'#9932CC', // DarkOrchid
		'#8B008B', // DarkMagenta
		'#556B2F', // DarkOliveGreen
		'#008B8B', // DarkCyan
		'#9400D3', // DarkViolet
		'#8B4513', // SaddleBrown
		'#800000', // Maroon
		'#800080', // Purple
		'#696969', // DimGray
		'#1E90FF', // DodgerBlue
		'#228B22', // ForestGreen
		'#FF00FF', // Fuchsia
		'#DCDCDC', // Gainsboro
		'#FFD700', // Gold
		'#DAA520', // GoldenRod
		'#808080', // Gray
		'#008000', // Green
		'#ADFF2F', // GreenYellow
		'#F0FFF0',  // HoneyDew
	],
} as const;

export const COLOR_SETS: Record<keyof typeof COLORS, string[]> = {
	...STANDARD_COLOR_SETS,
	predefined: [],
	customized: STANDARD_COLOR_SETS.s24,
} as const;

export const SIZES = [Size.A3, Size.A4, Size.A5, Size.x50, Size.x5040, Size.x30];

export const COMPLEXITY = {
	optimal: 'Оптимальная для размера картины',
	hard: 'Повышенная с большим кол-вом деталей',
	minimal: 'Легкая c крупными областями',
} as const;


export const COLORS = {
	predefined: 'Оптимальный предложенный алгоритмом',
	customized: 'Настроенный в ручную',
	...STANDARD_SETS,
} as const;

export const BACKGROUND = {
	normal: 'Оставить фон',
	blur: 'Размыть фон',
	remove: 'Удалить фон',
	sunbeams: 'Лучи',
	monotone: 'Однотонный фон',
};

export const BASE_PATH = '/paint-by-numbers';
export const PATH = {
	UPLOAD: `${BASE_PATH}/poll/upload`,
	MATERIAL: `${BASE_PATH}/poll/material`,
	EMAIL: `${BASE_PATH}/poll/email`,
	EDITOR: `${BASE_PATH}/editor`,
	MAP: `${BASE_PATH}/printers`,
} as const;

export const LOCAL_STORAGE_POLL_KEY = 'pbn-poll';
