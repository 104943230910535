import { PrinterStatus } from 'types/point';

export const PRINTER_STATUSES = [
	{
		name: 'Онлайн',
		code: PrinterStatus.active,
	},
	{
		name: 'Отключен',
		code: PrinterStatus.disabled,
	},
	{
		name: 'Недоступен',
		code: PrinterStatus.inaccessible,
	},
];
