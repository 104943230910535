import { BACKGROUND, COMPLEXITY } from 'constants/paint-by-numbers';

export enum Size {
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
    x50 = '50x50',
    x5040 ='50x40',
    x30 = '30x30',
}

export type Options = {
    size: Size;
    colors: string[];
    email: string;
    filePk?: string;
    file: string;
    complexity?: keyof typeof COMPLEXITY;
    background?: keyof typeof BACKGROUND;
}

export type Review = {
    image: string;
    description: string;
}

export type PbnSourceFile = {
    source_file: string;
    source_file_name: string;
    source_file_extension: string;
    source_file_size_bytes: number;
    created_at: string;
    id: string;
}

export type PbnResultFile = {
    id: string;
    created_at: string;
    status: number;
    status_name: string;
    source: string;
    result_file_preview: string;
    source_file_id: string;
    size: Size;
    background: keyof typeof BACKGROUND;
    difficulty: keyof typeof COMPLEXITY;
}
