import { PrinterStatus, Scanner } from 'types/point';

export function withActiveScanners(scanners: Scanner[], isStaff: boolean) {
	return scanners.length > 0 && scanners.some(({ status }) => isStaff || status === PrinterStatus.active);
}

export function getAvailableScanners(scanners: Scanner[], isStaff: boolean) {
	return scanners.filter(({ status }: Scanner) => (status === PrinterStatus.active || isStaff))
		.sort((s1: Scanner, s2: Scanner): number => s1.average_price - s2.average_price);
}
