export const CELEBRATIONS = {
	'01.01': { duration: 7, name: 'Новый год' },
	'07.01': { duration: 1, name: 'Рождество Христово' },
	'14.01': { duration: 1, name: 'Старый Новый год' },
	'25.01': { duration: 1, name: 'День студента (Татьянин день)' },
	'14.02': { duration: 4, name: 'День Святого Валентина' },
	'23.02': { duration: 4, name: 'День защитника Отечества' },
	'08.03': { duration: 8, name: 'Международный женский день' },
	'21.03': { duration: 1, name: 'Масленица' },
	'01.04': { duration: 1, name: 'День смеха (день дурака)' },
	'12.04': { duration: 1, name: 'День космонавтики' },
	'20.04': { duration: 2, name: 'День донора' },
	'01.05': { duration: 1, name: 'Праздник весны и труда' },
	'09.05': { duration: 3, name: 'День Победы' },
	'12.06': { duration: 3, name: 'День России' },
	'18.07': { duration: 1, name: 'День работников металлургии' },
	'01.09': { duration: 7, name: 'Первое сентября' },
	'05.09': { duration: 3, name: 'День учителя' },
	'04.10': { duration: 1, name: 'День народного единства' },
	'12.11': { duration: 1, name: 'День Конституции' },
} as const;
