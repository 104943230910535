import { CELEBRATIONS } from 'constants/celebrations';

import { createDate, getNDaysAgo, isDateBetween } from './date-format';


export function getCelebration(today = new Date()) {
	for (const celebrationDate in CELEBRATIONS) {
		const [day, month] = celebrationDate.split('.').map(Number);
		const endDate = createDate(day, month, today.getFullYear());
		const startDate = getNDaysAgo(endDate, CELEBRATIONS[celebrationDate].duration);
		if (isDateBetween(today, startDate, endDate)) {
			return ({ from: startDate, to: endDate, name: CELEBRATIONS[celebrationDate].name });
		}
		if (endDate.getFullYear() !== startDate.getFullYear()) {
			endDate.setFullYear(endDate.getFullYear() + 1);
			startDate.setFullYear(startDate.getFullYear() + 1);
		}
		if (isDateBetween(today, startDate, endDate)) {
			return ({ from: startDate, to: endDate, name: CELEBRATIONS[celebrationDate].name });
		}
	}
}

export function getPriceWithDiscount(price: number, discount: number) {
	return Math.min(Math.max(Math.floor(price * (100 - discount) / 100), 0), price);
}
